<template>
<v-app id="inspire">
    <div class="px-4">
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" />
        <v-container class="container--fluid grid-list-md text-center">
            <h1>Admin Accounts</h1>
        </v-container>
        <v-card elevation="2" outlined shaped tile class="pa-1">
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout wrap>
                            <v-flex xs12 sm6>
                                <v-text-field
                                    v-model="search"
                                    data-cy="search-user"
                                    append-icon="mdi-magnify"
                                    label="Search"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color="success" @click.stop="showUserForm('CREATED')" v-if="hasPermission('ROLE_CREATE_USER')">Create User</v-btn>
                                  <user-form ref="form" :selected-item="selectedUser" :is-show="showFormDialog" :form-type="userFormType" :roles="roles" v-on:close-dialog="closeDialog" v-on:save-user="saveUser" />
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
        </v-card>

        <v-data-table
            :hide-default-header="true"
            :headers="headers"
            :items="displayUsers"
            :search="search"
            :loading="loading"
            :options.sync="options"
            :server-items-length="totalUsers.length">
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr :colspan="headers.length">
                        <th v-for="header in headers" :key="header.text">
                            <span class="d-flex justify-center">{{header.text}}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                        <td> <span class="d-flex justify-center"> {{ item.username }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.firstName }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.lastName }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.dateCreated }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.isEnabled }} </span></td>
                        <td>
                            <span class="d-flex justify-center">
                                <v-btn fab dark x-small color="green" class="mr-2" @click.stop="viewDetails(item)">
                                  <v-icon dark medium>mdi-magnify-plus</v-icon>
                                </v-btn>
                                <v-btn fab dark x-small color="green" @click.stop="showUserForm('UPDATE', item)" v-if="hasPermission('ROLE_UPDATE_USER')">
                                    <v-icon dark medium>mdi-pencil</v-icon>
                                </v-btn>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Found no results.
                </v-alert>
            </template>
        </v-data-table>
    </div>
</v-app>
</template>

<script>
import API from '@/api/API.js'
import Loading from '@/views/components/Loading.vue'
import UserForm from '@/views/components/UserForm.vue'
import DetailsForm from '@/views/components/DetailsForm.vue'
import {
    mapGetters
} from 'vuex';

export default {
    name: 'Users',
    components: {
        Loading,
        UserForm,
        DetailsForm
    },
    data() {
        return {
            //filter/search variables
            search: '',
            filterQry: {
                qryName: "",
            },
            headers: [],
            displayUsers: [],
            remainderUsers: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            userFormType: 'CREATE',
            selectedUser: {},
            roles: [],
            paymentMethods: [],
            page: 1,
            totalUsers: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 5
            },
            details: {
                title: '',
                item: {}
            },
            query: {
                code: '',
                name: ''
            },
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false
        }
    },
    watch: {
        options: {
            handler() {
                console.log('1')
                this.queryData()
                this.setData()
            },
        },
        deep: true,
    },
    computed: {
        //get role attributes
        ...mapGetters([
            'attributes',
            'user',
            'authorities'
        ]),
    },
    async mounted() {
        this.setHeaders()
        this.queryData()
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x === authority)){
            return true
          }
          return false
        },
        async queryData() {
            this.loading = true
            try {
                const userListResponse = await API.getUsers()
                console.log(userListResponse)
                if (!userListResponse || userListResponse.error) {
                    //error getting data
                    console.log(`${userListResponse.error}`)
                } else {
                    this.totalUsers = userListResponse.length
                    this.displayUsers = userListResponse
                }
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        },
        async setData() {
          try {
                const listResponse = await API.getAllRoles()
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    this.roles = listResponse.roles
                    console.log(listResponse.roles[0])
                }
            } catch (e) {
                console.log(e)
            }
        },
        setHeaders() {
            var headers = [{
                    text: 'Username',
                    value: 'username'
                },
                {
                    text: 'First name',
                    value: 'firstName'
                },
                {
                    text: 'Last name',
                    value: 'lastName'
                },
                {
                    text: 'Date Created',
                    value: 'dateCreated'
                },
                {
                    text: 'Is Enabled',
                    value: 'isEnabled'
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false
                }
            ]
            this.headers = headers
        },
        showUserForm(what, item = {}) {
            this.userFormType = what
            this.selectedUser = item
            this.showFormDialog = true
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.$router.go(0);
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        async saveUser(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                if (value.formType === 'CREATED') {
                    this.loadingMessage = `Creating new user <strong><i>${value.username}</i></strong>`
                    setTimeout(async () => {
                        const createResponse = await API.createUsers(value)
                        this.loadingFinished = true
                        if (!createResponse || createResponse.error) {
                            this.loadingMessage = createResponse.error.message
                        } else {
                            this.loadingMessage = `Successfully created user <strong><i>${value.username}</i></strong>`
                        }
                    }, 2000);
                } else if (value.formType === 'UPDATE') {
                    this.loadingMessage = `Updating user <strong><i>${value.username}</i></strong>`
                    setTimeout(async () => {
                        const updateResponse = await API.updateUsers(value)
                        this.loadingFinished = true
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = updateResponse.error.message
                        } else {
                            this.loadingMessage = `Successfully updated user <strong><i>${value.username}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.form.resetForm()
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command"
                    this.loadingFinished = true
                }
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        viewDetails(details) {
          this.details.title = details.username
          this.details.item = Object.assign({}, details);
          this.isShowDetails = true
        }
        // search(event, type) {
        //     this.query[type] = event
        // }
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%;
    margin-bottom: 50px;
}
</style>

<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card ref="formCard">
        <v-card-title>
          <span class="headline" v-if="formType === 'UPDATE'">Update User</span>
          <span class="headline" v-else>Create New User</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formEntry">
            <v-container grid-list-md>
              <v-layout wrap>
                <v-dialog
                  data-cy="user-loading-dialog"
                  v-model="loading"
                  persistent
                  hide-overlay
                  max-width="400px"
                >
                  <v-card color="primary" dark>
                    <v-card-text>
                      Please stand by
                      <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                      ></v-progress-linear>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-flex xs12>
                  <v-text-field
                    label="Username*"
                    :readonly="formType === 'UPDATE'"
                    v-model="form.username"
                    required
                    hint="one word, camel case *UserName*"
                    :rules="[rules.requiredField]"
                    data-cy="form-username"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 v-if="formType === 'CREATED'">
                  <v-text-field
                    label="Password*"
                    v-model="form.password"
                    required
                    :rules="[rules.requiredField]"
                    :append-icon="passwordType.password ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (passwordType.password = !passwordType.password)"
                    type='password'
                    data-cy="form-password"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 v-if="formType === 'CREATED'">
                  <v-text-field
                    label="Confirm Password*"
                    v-model="form.confirmPassword"
                    required
                    :rules="[rules.requiredField, rules.matchPassword]"
                    :append-icon="
                      passwordType.confirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    @click:append="
                      () => (passwordType.confirmPassword = !passwordType.confirmPassword)
                    "
                    :type="passwordType.confirmPassword ? 'password' : 'text'"
                    data-cy="form-confirm-password"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="First Name*"
                    v-model="form.firstName"
                    required
                    :rules="[rules.requiredField]"
                    data-cy="form-first-name"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Last Name*"
                    v-model="form.lastName"
                    required
                    :rules="[rules.requiredField]"
                    data-cy="form-last-name"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Email*"
                    v-model="form.email"
                    required
                    :rules="[rules.requiredField, rules.properEmail]"
                    data-cy="form-email"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="form.userType"
                    data-cy="user-type"
                    :items="userTypeList"
                    item-text="name"
                    item-value="name"
                    required
                    :rules="[rules.requiredSelection]"
                    label="User Type*"
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-autocomplete
                    :items="roleList"
                    item-text="name"
                    item-value="name"
                    label="Groups*"
                    data-cy="group-name"
                    v-model="form.role"
                    required
                    :rules="[rules.requiredSelection]"
                    chips
                    deletable-chips
                    multiple
                    return-object
                  >
                    <v-list-item
                      slot="prepend-item"
                      ripple
                      @click="toggle"
                      v-if="roleList.length > 0"
                    >
                      <v-list-item-action>
                        <v-icon :color="form.role.length > 0 ? 'indigo darken-4' : ''">{{
                          icon
                        }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item>
                    <v-divider slot="prepend-item" class="mt-2"></v-divider>
                    <v-divider slot="append-item" class="mb-2"></v-divider>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 md4 v-if="formType === 'UPDATE'">
                  <v-switch
                    v-model="form.isEnabled"
                    :label="`Is Enabled: ${
                      form.isEnabled ? form.isEnabled.toString() : 'false'
                    }`"
                    data-cy="form-enable-disable"
                  ></v-switch>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text v-on:click="close">Close</v-btn>
          <v-btn color="blue darken-1" text v-on:click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import API from "@/api/API.js";

export default {
  props: {
    isShow: Boolean,
    formType: String,
    selectedItem: Object,
    roles: Array,
  },
  computed: {
    populatedForm() {
      return {
        username: this.form.username,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        isEnabled: this.form.isEnabled,
        password: this.form.password,
        confirmPassword: this.form.confirmPassword,
        email: this.form.email,
        role: this.formatRoleList(true, this.form.role),
        formType: this.formType,
        userType: this.form.userType,
      };
    },
    haveAllRole() {
      return this.form.role.length === this.roleList.length;
    },
    selectedRole() {
      return this.form.role.length > 0 && !this.haveAllRole;
    },
    icon() {
      if (this.haveAllRole) return "mdi-close-box";
      if (this.selectedRole) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    isShow: {
      handler(value) {
        this.dialog = value;
      },
    },
    dialog: {
      handler(value) {
        if (value === true) {
          this.initializeForm();
          this.getUserTypes();
        }
      },
    },
    roles: {
      handler(value) {
        this.roleList = value ? value : ["GROUP_SUPER_ADMIN", "GROUP_SYSADMIN"];
      },
    },
    deep: true,
  },
  data() {
    return {
      loading: false,
      dialog: this.isShow ?? false,
      userTypeList: [],
      roleList: this.roles
        ? this.formatRoleList(false, this.roles)
        : ["GROUP_SUPER_ADMIN", "GROUP_SYSADMIN"],
      form: {
        role: [],
      },
      passwordType: {
        password: String,
        confirmPassword: String,
      },
      rules: {
        requiredField: (v) => !!v || "Required field.",
        requiredSelection: (v) =>
          (!!v && v.length > 0) || "Required at least one selection",
        matchPassword: (v) => v === this.form.password || "Does not match new password.",
        properEmail: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid. Ex. juandelacruz@apollo.com.ph",
      },
    };
  },
  mounted() {
    this.initializeForm();
    this.getUserTypes();
  },
  methods: {
    async getUserTypes() {
      try {
        this.loading = true;
        const listResponse = await API.getUserTypes();
        if (!listResponse || listResponse.error) {
          //error getting data
          console.log(`${listResponse.error}`);
        } else {
          this.userTypeList = listResponse;
          this.loading = false;
          return this.userTypeList;
        }
      } catch (e) {
        console.log(e);
        //display error prompt
        this.loading = false;
        this.userTypeList = [];
        return this.userTypeList;
      }
    },
    formatRoleList(isPopulated, list) {
      if (isPopulated) {
        try {
          return list.replace(/"/g, '"');
        } catch (e) {
          return list;
        }
      } else {
        try {
          return list.map(({ role }) => ({ name: role }));
        } catch (e) {
          return list;
        }
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.haveAllRole) {
          this.form.role = [];
        } else {
          this.form.role = this.roleList.slice();
        }
      });
    },
    close() {
      this.dialog = false;
      this.$refs.formEntry.reset();
      this.$refs.formCard.$el.scrollIntoView({
        behavior: "smooth",
      });
      this.$emit("close-dialog", this.formType);
    },
    save() {
      if (this.$refs.formEntry.validate()) {
        this.$emit("save-user", this.populatedForm);
        this.$refs.formCard.$el.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    resetForm() {
      this.$refs.formEntry.reset();
    },
    initializeForm() {
      if (this.formType === "CREATED") {
        this.form = {
          username: undefined,
          firstName: undefined,
          lastName: undefined,
          password: undefined,
          confirmPassword: undefined,
          email: undefined,
          isEnabled: false,
          userType: undefined,
          role: [],
        };
      } else if (this.formType === "UPDATE" && this.selectedItem) {
        var selectedRoles = [];
        var list = this.selectedItem.roles;
        if (list) {
          if (Object.prototype.toString.call(list) === "[object Object]") {
            if (list) {
              selectedRoles.push(list);
            } else {
              selectedRoles.push(JSON.stringify(list));
            }
          } else if (Object.prototype.toString.call(list) === "[object Array]") {
            for (var index in list) {
              console.log(index, list[index]);
              if (list[index] !== "") {
                selectedRoles.push(list[index]);
              }
            }
          } else {
            selectedRoles.push(list);
          }
          console.log(selectedRoles);
        }
        this.form = {
          username: this.selectedItem.username,
          firstName: this.selectedItem.firstName,
          lastName: this.selectedItem.lastName,
          isEnabled: this.selectedItem.isEnabled,
          email: this.selectedItem.email,
          role: selectedRoles,
          password: undefined,
          confirmPassword: undefined,
          userType: this.selectedItem.userType,
        };
      }
    },
  },
};
</script>
